#name {
	margin-top: 0.75em;
	font-size: clamp(3rem, 12.62vw + 0.476rem, 11.25rem);
	line-height: 0.9em;
}

#title {
	margin-top: 0.25em;
	letter-spacing: 0.05em;
	font-size: clamp(1.75rem, 4.207vw + 0.909rem, 4.5rem);
}

#name,
#title {
	text-align: center;
	text-transform: uppercase;
}