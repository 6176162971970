#socials {
    display: flex;
    margin-bottom: 3.5em;
    font-size: clamp(0.875rem, 0.956vw + 0.684rem, 1.5rem);
    text-align: center;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: none;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
    }

    a:hover {
        color: #101010;
    }

    @media (max-width: 599px) {
        margin-bottom: 5em;
    }

    @media (min-width: 1500px) {
        margin-bottom: 8em;
    }
}