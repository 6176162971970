span {
    font-style: normal;
}

#bio {
    margin: auto calc(10vw + 1.8em);
    font-family: ivypresto-display, serif !important;
    font-size: clamp(0.813rem, 1.816vw + 0.449rem, 2rem);
    font-weight: 300 !important;
    font-style: italic;
}
