// Adobe fonts
@import url("https://use.typekit.net/woy4mbd.css");

html, body {
  min-height: 100%;
  height: auto;
}

// Base font size property
html {
  width: 100%;
  font-size: 16px;
  scroll-behavior: initial;
}

body {
  font-family: bilo, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: ivypresto-display, serif !important;
  font-weight: 100 !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: clamp(0.75rem, 0.765vw + 0.597rem, 1.25rem);
}

// Center vertically
.viewport-fill {
  width: 100%;
  min-height: 100vh;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.spacer {
  padding-bottom: 15em;
}