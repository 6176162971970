@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');

$text: #f5f5f5;

.bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: url('../assets/gradient.jpg');
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.App {
  width: 100%;
  height: 100%;
  color: $text;

  a {
    color: $text;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --padw: max(20px, 4vmin);
  --padh: max(36px, 6vmin);

  @media (min-width: 600px) {
    --padw: max(40px, 4vmin);
    --padh: max(60px, 6vmin);
  }
}

#logo {
  position: fixed;
  top: calc(var(--padh) - 1.25em);
  left: var(--padw);
  font-family: 'Nanum Myeongjo', serif !important;
  font-weight: 400 !important;
  font-size: clamp(1.5rem, 0.956vw + 1.309rem, 2.125rem);
  z-index: 20;

  a {
    text-decoration: none;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
  }

  a:hover {
    color: #a3a3a3;
  }
}

#mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 15;
}

#mask-top, #mask-bottom {
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--padh);
}

#mask-top {
  top: 0;
  background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,1) 50%);
}

#mask-bottom {
  bottom: 0;
  background: linear-gradient(0deg, rgba(188,192,209,1) 50%, rgba(188,192,209,0) 100%);
}

#frame {
  position: fixed;
  top: var(--padh);
  right: var(--padw);
  bottom: var(--padh);
  left: var(--padw);
  pointer-events: none;
  z-index: 20;
}

.frame-border {
  position: absolute;
  background-color: #f5f5f5;
}

#border-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

#border-right {
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
}

#border-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

#border-left {
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
}

#copyright {
  position: fixed;
  right: var(--padw);
  bottom: calc(var(--padh) - 1.75em);
  font-size: clamp(0.75rem, 0.382vw + 0.674rem, 1rem);
  text-align: right;
  z-index: 20;
}