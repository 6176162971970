.project-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.project-frame {
    display: flex;
    width: 100%;
    aspect-ratio: 1.54/1;
    border: 1.5px solid #f5f5f5;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.05);
    overscroll-behavior: none;
    scrollbar-width: none;
    -ms-overflow-style: none;

    img {
        display: flex;
        width: 100%;
        margin: 0 auto;
        object-fit: contain;
    }

    @media (min-width: 600px) {
        width: 93%;
    }
}

.project-frame::-webkit-scrollbar {
    display: none;
}

#auxcord {
    background-color: #0c0c0c;
    aspect-ratio: 1.74/1;
}

.description-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.description {
    text-align: left;

    h2 {
        font-size: clamp(1.25rem, 2.677vw + 0.715rem, 3rem);
        font-weight: 300 !important;
    }

    @media (min-width: 600px) {
        padding-right: 60px;
    }
}

.link {
    font-size: 0.9em; // fix
    font-weight: 500 !important;
    text-transform: uppercase;

    a {
        text-decoration: none;
    }

    img {
        transition: 0.4s ease-in;
    }
}

.link:hover img {
    transform: translateX(20%);
}