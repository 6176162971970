#gallery-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: visible;
}

#gallery-frame {
    width: 100%;
    aspect-ratio: 0.56/1;
    border: 1.5px solid #f5f5f5;
    overflow-y: auto;
    overscroll-behavior: none;
    scrollbar-width: none;
    -ms-overflow-style: none;

    img {
        display: flex;
        width: 100%;
        object-fit: contain;
    }

    @media (min-width: 600px) {
        width: 90%;
        aspect-ratio: 0.78/1;
    }
}
  
#gallery-frame::-webkit-scrollbar {
    display: none;
}

#gallery-nav-container {
    margin-top: 5px;
}

.arrow {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    transition: 0.4s ease-in-out;

    img {
        width: auto;
        height: 10px;
    }
}

.arrow:hover {
    transform: translateX(20%);
}

#work-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.55em;
    text-align: left;
    justify-content: flex-end;


    h2 {
        font-size: clamp(1.25rem, 2.677vw + 0.715rem, 3rem);
        font-weight: 300 !important;
    }

    @media (min-width: 600px) {
        padding-right: 60px;
    }
}